$darkBlue: #00375e;
$darkerBlue: #2d4060;
$orange: #fd8672;
$peach: #f5d6c6;
$green: #6f828c;
$olive: #8d8d8d;
$tan: #ecdec9;
$white: #f7f7f7;
$grey: #969696;
$gold: #c4a465;
$rsvp-grey: #6d6d6d;