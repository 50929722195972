@import '../_colors.scss';
@import '../_breakpoints.scss';

.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.nav__content {
  background-color: $white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__list-item {
  list-style-type: none;
  white-space: nowrap;
  font-family: 'baskerville_small_casmallcaps', 'Laila', serif;
  font-size: 24px;
  color: $darkBlue;
  user-select: none;

  @include breakpoint(tablet) {
    font-size: 18px;
  }

  @include breakpoint(phablet) {
    font-size: 15px;
  }

  @include breakpoint(mobile) {
    font-size: 13px;
  }

  a {
    display: inline-block;
    text-decoration: none;
    transform: scale(1);
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &:visited,
    &:link {
      color: inherit;
    }

    &:hover {
      transform: scale(1.1);
      color: $darkerBlue;
    }

    &.active {
      font-weight: bold;
    }
  }

  @media (hover: none), (hover:on-demand) {
    a:link {
      transform: scale(1);
    }
  }
}

.nav__list-item:not(:last-child) {
  padding-right: 48px;

  @include breakpoint(phablet) {
    padding-right: 18px;
  }

  @include breakpoint(mobile) {
    padding-right: 12px;
  }
}

.nav__spacer {
  height: 120px;
  display: block;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+1,0.1+26,0+100 */
  background: -moz-linear-gradient(top, $white 0%, $white 1%, rgba(255,255,255,0.1) 26%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $white 0%, $white 1%, rgba(255,255,255,0.1) 26%, rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $white 0%, $white 1%, rgba(255,255,255,0.1) 26%, rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  pointer-events: none;
}

.nav__list {
  padding-inline-start: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-wrap: wrap;
}

button {
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

button:hover {
  text-decoration: underline;
}
