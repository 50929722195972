@import '../_colors.scss';
@import '../_constants.scss';
@import '../_breakpoints.scss';

.profile-card {
    min-height: 520px;
    width: $mobileCardWidth;
    background-color: white;
    user-select: none;
    box-shadow: 4px 4px 8px $grey;
    margin: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(phablet) {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(100% - 20px);
    }
}

.profile-card__image {
    height: 240px;
    width: $mobileCardWidth;
}

.profile-card__name_and_distance {
    border-bottom: 1px solid $grey;
    flex: 0 0 auto;
}

.profile-card__content {
    padding: 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.profile-card__name_and_distance,
.profile-card__description {
    padding: 0 10px;
}

.profile-card__description {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profile-card__then-and-now {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.profile-card__name,
.profile-card__prompt {
    font-weight: bold;
}

.profile-card__distance,
.profile-card__relation,
.profile-card__then,
.profile-card__now {
    font-size: 15px;
}

.profile-card__location_pin {
    height: 14px;
    margin-right: 3px;
}

