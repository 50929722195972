@import '../_colors.scss';
@import '../_breakpoints.scss';

.story {
    box-sizing: border-box;
    border: 16px solid $white;
    background-color: $white;
}

.story__paragraph {
    font-family: 'Libre Baskerville', 'Laila', sans-serif;
    font-size: 24px;

    @include breakpoint(tablet) {
        font-size: 18px;
    }

    @include breakpoint(phablet) {
    	font-size: 15px;
    }

    @include breakpoint(mobile) {
    	font-size: 13px;
    }
}
