@import '../_colors.scss';
@import '../_breakpoints.scss';

.photo-strip {
    overflow: hidden;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 400ms cubic-bezier(0.310, 0.440, 0.445, 1.650);
    transition-delay: 200ms;
}

.photo-strip__hidden {
    opacity: 0;
    transform: translateY(-5px);
}

.photo-strip__container {
    border-style: dashed;
    background-color: black;
    display: inline-block;
}

.photo-strip__image_container {
    margin: 8px 4px;
    border-top: 8px dashed $white;
    border-bottom: 8px dashed $white;
    display: flex;

    @include breakpoint(phablet) {
        margin: 6px 3px;
        border-top: 6px dashed $white;
        border-bottom: 6px dashed $white;
    }

    @include breakpoint(mobile) {
        margin: 4px 2px;
        border-top: 4px dashed $white;
        border-bottom: 4px dashed $white;
    }
}

.photo-strip__image {
    background-color: black;
    overflow: hidden;
    margin: 8px 4px;

    @include breakpoint(phablet) {
        margin: 6px 3px;
    }

    @include breakpoint(mobile) {
        margin: 4px 2px;
    }
}

.photo-strip__inner_image {
    height: 18vw;
    max-height: 200px;
    width: auto;
    transform: scale(1);
    transition: transform 200ms linear;

    @include breakpoint(mobile) {
        max-height: 66px;
    }

    &:hover {
        transform: scale(1.1);
    }

    @media (hover: none), (hover:on-demand) {
        a:link {
            transform: scale(1);
        }
    }
}
