@import '../_colors.scss';
@import '../_breakpoints.scss';


.google-map {
    height: 675px;
    margin: 0 auto;
    display: flex;
    margin-bottom: -50px;
}

.google-map__iframe {
    position: relative;
    top: -50px;
    border: none;
    flex: 1 1 auto;
}
