@import '../_colors.scss';
@import '../_constants.scss';

a {
  text-decoration: none;
  color: black;
}

.hotel {
  margin: 0 auto;
  min-height: 380px;
  width: $mobileCardWidth;
  background-color: white;
  box-shadow: 4px 4px 8px $grey;
  margin: 16px;
}

.hotel__image {
  height: 240px;
  width: $mobileCardWidth;
}

.hotel__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hotel__logo {
  height: 120px;
  width: 200px;
  margin: 0 auto;
}

.hotel__logo-image {
  background-size: contain;
}

.hotel__description {
  font-family: 'Libre Baskerville', 'Laila', serif;
  margin: 10px;
}
