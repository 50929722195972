@import '../_colors.scss';

.carousel {
    overflow: hidden;
    position: relative;
}

.carousel__images {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.carousel__arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.35;
    transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        opacity: 1;
    }
}

.carousel__arrow_left,
.carousel__arrow_right {
    outline: none;
    transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;

    &:disabled {
        opacity: 0;
        cursor: default;
    }

    &:active {
        transform: translate(2px 2px);
    }

    svg {
        height: 28px;
        width: 28px;
    }
}

.carousel__arrow_left {
    justify-content: flex-start;
    padding-left: 8px;
}

.carousel__arrow_right {
    justify-content: flex-end;
    padding-right: 8px;
}