@import '../_colors.scss';

.wedding-party {
    padding: 0 30px; 
    margin-top: -120px;
}

.wedding-party__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Libre Baskerville', 'Laila', serif;
}

.wedding-party__bridesmaids,
.wedding-party__groomsmen {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}

.wedding-party__tinder_logo {
    width: 30px;
    height: 30px;
    margin: 0 8px;
}
