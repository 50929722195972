@import '../_colors.scss';
@import '../_breakpoints.scss';

.our-story {
    display: flex;
    flex-wrap: wrap;
    margin: -80px auto 80px;
    max-width: 1080px;
    justify-content: center;
    text-align: left;
}

.our-story__story {
    margin: 80px auto;
    overflow: hidden;
    box-shadow: 4px 4px 16px 8px #969696;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 400ms cubic-bezier(0.310, 0.440, 0.445, 1.650);
    transition-delay: 200ms;
    max-width: 900px;

    @include breakpoint(phablet) {
        max-width: calc(100% - 20px);
    }
}

.our-story__story__preloading {
    opacity: 0;
    transform: translateY(-5px);
}

.our-story__film_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.our-story__film {
    margin-top: 24px;

    &:nth-child(1) {
        transform: rotate(-5deg);
        z-index: 1;
    }

    &:nth-child(2) {
        transform: rotate(3deg);
        z-index: 2;
    }

    &:nth-child(3) {
        transform: rotate(-2deg);
        z-index: 3;
    }

    &:nth-child(4) {
        transform: rotate(1deg);
        z-index: 4;
    }
}
