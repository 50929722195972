@import '../_colors.scss';
@import '../_breakpoints.scss';

.header {
	line-height: 72px;
    font-family: 'wedding_scriptregular', 'Satisfy', cursive;
    font-size: 60px;
	width: 100%;
	z-index: 1;
    text-align: center;
    color: $darkerBlue;
    background-color: $white;
    user-select: none;

    @include breakpoint(phablet) {
        font-size: 48px;
        line-height: 60px;
    }
}

.header__date {
    font-size: 18px;
    font-family: 'baskerville_small_casmallcaps', sans-serif;
    line-height: 28px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__month,
.header__year {
    flex: 1 1 0;

    @include breakpoint(mobile) {
        border-top: 1px solid;
        border-bottom: 1px solid;
    }
}

.header__month {
    text-align: right;
    margin-right: 12px;

    @include breakpoint(mobile) {
        margin-right: 6px;
    }
}

.header__year {
    text-align: left;
    margin-left: 12px;

    @include breakpoint(mobile) {
        margin-left: 6px;
    }
}

.header__day {
    font-size: 28px;
    font-family: 'ritzynormalregular';
    flex: 0 0 auto;
}
