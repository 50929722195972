body {
    overflow-x: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
}

main {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
