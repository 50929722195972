@import '../_breakpoints.scss';
@import '../_constants.scss';

.accommodations {
	margin: -80px 0;
	text-align: center;
}

.accommodations__info {
	margin-top: 16px;
	font-family: 'baskerville_small_casmallcaps', 'Laila', serif;
	font-size: 20px;
}

.accommodations__travel,
.accommodations__hotels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.accommodations__map {
	width: 900px;
	height: 675px;
	margin: 0 auto 10px;

	@include breakpoint(phablet) {
		width: calc(100% - 20px);
		margin: 0 10px 10px;
	}
}
