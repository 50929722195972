@import '../_colors.scss';

.info-card {
  font-family: 'Libre Baskerville', 'Laila', serif;
  margin: 0 auto;
  min-height: 380px;
  min-width: 260px;
  max-width: 400px;
  background-color: white;
  box-shadow: 4px 4px 8px $grey;
  margin: 16px;
}

.info-card__logo {
  height: 240px;
  width: 280px;
}

.info-card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px 20px;
}
