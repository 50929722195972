@import '../_colors.scss';
@import '../_constants.scss';
@import '../_breakpoints.scss';

.event-card {
    position: relative;
    flex: 0 1 500px;
    min-width: $mobileCardWidth;
    max-width: 500px;
    background-color: white;
    user-select: none;
    box-shadow: 4px 4px 8px $grey;
    margin: 16px;
    display: flex;
    flex-direction: column;
    text-align: left;

    p {
        margin: 4px 0;
    }

    .event-card__subtitle {
        margin-bottom: 16px;
    }
}

.event-card:before {
    position: absolute;
    content: '';
    top: -5px;
    left: 0;
    height: 10px;
    width: 99%;
    background-size: 9px 12px;
    background-image: -webkit-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -moz-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -ms-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -o-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: radial-gradient(circle at 5% 40%, transparent 70%, #555 20%);
}

.event-card__date_and_time {
    background-color: $orange;
    border-bottom: 1px solid $grey;
}

.event-card__date_and_time,
.event-card__title_and_details {
    padding: 10px 10px;
}

.event-card__title_and_details {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.event-card__title_and_subtitle {
    flex: 0 0 auto;
    margin-bottom: 24px;
}

.event-cart__time_location_and_attire {
    flex: 1 0 auto;
}

.event-card__date,
.event-card__title,
.event-card__attire {
    font-weight: bold;
}

.event-card__time,
.event-card__location {
    flex: 0 0 0;
}

.card__location {
    display: flex;
    align-items: center;
}

.event-card__location_pin {
    height: 14px;
    margin-right: 3px;
}
